/* navbar.css */

/* General Styles for Navbar */
.navbar {
    transition: all 0.3s ease-in-out;
    z-index: 1050;
  }
  
  .navbar.bg-dark {
    background-color: #343a40 !important;
  }
  
  .navbar .nav-link {
    color: #ffffff !important;
    font-weight: 500;
    margin-right: 1rem;
    transition: color 0.2s;
  }
  
  .navbar .nav-link:hover {
    color: #007bff !important;
    text-decoration: underline;
  }
  
  /* NavDropdown styles */
  .nav-dropdown {
    font-size: 14px;
  }
  
  .nav-dropdown .dropdown-item {
    color: #343a40 !important;
  }
  
  .nav-dropdown .dropdown-item:hover {
    background-color: #007bff !important;
    color: #ffffff !important;
  }
  
  /* Profile Image */
  .navbar .user-profile img {
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ffffff;
  }
  
  .navbar .user-profile {
    display: flex;
    align-items: center;
  }
  
  .navbar .user-profile .user-info {
    margin-left: 10px;
    color: #ffffff;
  }
  
  /* Navbar Collapse Styles */
  .navbar-toggler {
    border: none;
    outline: none;
  }
  
  .navbar-toggler-icon {
    background-color: #ffffff;
  }
  
  .navbar-collapse {
    justify-content: space-between;
  }
  
  .navbar-collapse .ms-auto {
    display: flex;
    align-items: center;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .navbar .nav-link {
      margin-right: 0;
      padding: 10px 15px;
    }
  
    .navbar .dropdown-menu {
      width: 100%;
      text-align: center;
    }
  }
  
  /* User Dropdown */
  #user-dropdown {
    background-color: #343a40 !important;
    color: #ffffff;
  }
  
  #user-dropdown .dropdown-item {
    color: #ffffff;
  }
  
  #user-dropdown .dropdown-item:hover {
    background-color: #495057 !important;
    color: #ffffff !important;
  }
  
  /* Add padding to avoid overlap with fixed navbar */
  body {
    padding-top: 80px;
  }
  