.telegram-button {
    position: fixed;
    bottom: 40px;
    right: 20px;
    background-color: #0088cc;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1000; /* Asegura que esté siempre visible */
  }
  
  .telegram-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  }
  
  .telegram-icon {
    width: 30px;
    height: 30px;
  }
  