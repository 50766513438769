/* Estilos generales para centrar la caja de examen */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
  }
  
  .exam-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Alinear el contenido al inicio, no centrado vertical */
    height: 100%;
    width: 100%;
    padding: 20px;
    margin-top: 100px; /* Ajusta según la altura de la navbar */
    margin-bottom: 50px; /* Para dar espacio al final */
  }
  
  /* Estilos para la caja del examen */
  .exam-box {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    text-align: left;
  }
  
  /* Título principal */
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Estilos para los campos del formulario */
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Estilos generales para los inputs y selects */
  .form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-top: 5px;
  }
  
  /* Botón principal */
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px 20px;
    font-size: 18px;
    width: 100%;
    border-radius: 5px;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  /* Botón de siguiente bloque */
  .btn-success {
    background-color: #28a745;
    border-color: #28a745;
    padding: 10px 20px;
    font-size: 18px;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  
  /* Estilos para los bloques de preguntas */
  .question-block {
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Estilos para las opciones */
  .options-list {
    list-style: none;
    padding: 0;
  }
  
  .option-item {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .option-item:hover {
    background-color: #f4f4f4;
  }
  
  .selected {
    background-color: #007bff;
    color: white;
  }
  
  /* Estilos responsivos para pantallas pequeñas */
  @media (max-width: 768px) {
    .exam-box {
      padding: 20px;
      max-width: 100%;
    }
  }