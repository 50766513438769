.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.login-form, .register-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
  background: rgba(255, 255, 255, 0.8); /* Fondo blanco semi-transparente para los componentes */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* styles.css */
body {
  background-image: url('/public/images/eunacom-background.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  
}

/* Asegura que todas las tarjetas tengan la misma altura */
.card-uniform {
  height: 100%;
}

/* Añade animación y estilo en hover para mejorar la apariencia */
.card-uniform:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s, box-shadow 0.2s;
}

/* Asegura que las imágenes dentro de las tarjetas se ajusten correctamente */
.card-uniform .card-img-top {
  object-fit: cover;
  
  height: 200px; /* Ajusta según la necesidad */
  width: 100%;
}

/* Estilo para los botones dentro de las tarjetas */
.card-uniform .btn {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: auto; /* Mantiene el botón al fondo de la tarjeta */
}

.button-container {
  display: flex;
  justify-content: center; /* Centra el botón horizontalmente */
  margin-top: 20px; /* Espaciado opcional arriba del botón */
}
/* Estilos para las secciones */
.seccion {
  flex: 1; /* Ajusta el tamaño de la sección */
  margin: 20px; /* Agrega un margen entre secciones */
  padding: 20px; /* Agrega un padding dentro de la sección */
  background-color: #f7f7f7; /* Agrega un fondo claro a la sección */
  border: 1px solid #ddd; /* Agrega un borde a la sección */
  border-radius: 10px; /* Agrega un borde redondeado a la sección */
}

/* Estilos para los botones */
.boton {
  width: 100%; /* Ajusta el tamaño del botón */
  height: 50px; /* Ajusta el tamaño del botón */
  margin: 10px; /* Agrega un margen entre botones */
  padding: 10px; /* Agrega un padding dentro del botón */
  font-size: 18px; /* Ajusta el tamaño del texto del botón */
  border: none; /* Elimina el borde del botón */
  border-radius: 10px; /* Agrega un borde redondeado al botón */
  cursor: pointer; /* Agrega un cursor de puntero al botón */
}

/* Estilos para emailService */
.modal-body {
  padding: 30px;
}

.input-group .form-control {
  border-radius: 0;
}

.input-group .btn {
  border-radius: 0;
}

.light {
  background-color: #ffffff;
  color: #000000;
}

.dark {
  background-color: #121212;
  color: #ffffff;
}