body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    color: #212529;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  button {
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  button:hover {
    opacity: 0.9;
  }
  